import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import JoinKudaBusiness from "../components/body/pages/joinKudaBusiness"

const JoinKudaBusinessPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/joinkudabusiness/"}
      title="Download Kuda Business | The all-in-one business manager you need"
      description="Download Kuda Business, Register your business, generate and send invoices, receive POS payments, pay vendors, make bulk transfers, and free up time for the rest of your life."
    />
    <JoinKudaBusiness />
  </Layout>
)

export default JoinKudaBusinessPage
