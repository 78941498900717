import React, { useEffect, Fragment } from "react"
import DarkBelowFoldCards from '../general/darkBelowFoldCards'
import DashboardIcon from '../../../../assets/img/light-dashboard.inline.svg';
import SoftPOSIcon from "../../../../assets/img/kuda-softpos.inline.svg"
import TimeIcon from '../../../../assets/img/time-light.inline.svg';
import { scrollToElement } from "../../../utility/utils"
import Illustration from "../../../../assets/img/kudabusiness-open-account.svg"
import IllustrationBlur from "../../../../assets/img/business/blur/kuda-join-hero-blur.inline.svg"
import JoinKudaHeader from "../joinKuda/joinKudaHeader";

const entryContent = {
    title: "Download Kuda Business, open an account!",
    subtitle:
        "Scan the QR code below with your phone camera to download Kuda.",
    illustration: Illustration,
    fallback: <IllustrationBlur />
}


const kudaTopFeatures = [{
    icon: <DashboardIcon />,
    text: "Create and manage multiple business accounts."
}, {
    icon: <SoftPOSIcon />,
    text: "Turn your smartphone into a POS with Kuda softPOS."
}, {
    icon: <TimeIcon />,
    text: "Send up to ₦250 million daily."
}]



const JoinKudaBusiness = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <JoinKudaHeader className="overflow-visible"
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
                isBusiness={true}
                fallback={entryContent.fallback}

            />
            <DarkBelowFoldCards topFeatures={kudaTopFeatures} />

        </Fragment>
    )
}

export default JoinKudaBusiness;
